import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Accordion } from "@chakra-ui/react";
import BasicDataAccordion from "../organisms/BasicDataAccordion";
import InfoAccordion from "../organisms/InfoAccordion";
const InfoModal = ({ isOpen, onClose, holderInfo, isLoading }) => {
  return (
    isOpen && (
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Información del Titular</ModalHeader>
          <ModalBody>
            {isLoading ? (
              <Spinner size="xl" />
            ) : (
              <Accordion allowMultiple>
                {holderInfo.basicData && (
                  <BasicDataAccordion categoryData={holderInfo.basicData} />
                )}
                {holderInfo.financial && (
                  <InfoAccordion categoryData={holderInfo.financial} categoryName="Mi información financiera"/>
                )}
                {holderInfo.advisors && (
                  <InfoAccordion categoryData={holderInfo.advisors} categoryName="Asesores" />
                )}
                {holderInfo.docs && (
                  <InfoAccordion categoryData={holderInfo.docs} categoryName="Documentos importantes" />
                )}
                {holderInfo.whatToDoWhenIamGone && (
                  <InfoAccordion categoryData={holderInfo.whatToDoWhenIamGone} categoryName="Que hacer cuando me vaya"/>
                )}
                {holderInfo.myInfoForWhenIamGone && (
                  <InfoAccordion categoryData={holderInfo.myInfoForWhenIamGone} categoryName="Información para cuando no esté aquí"/>
                )}
                
              </Accordion>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};


    /* eslint-disable-next-line */
  const categoryTitles = {
    advisors: {
      title: "Asesores",
      subcategories: {
        advisorName: "Nombre del Asesor",
        advisorContact: "Contacto del Asesor",
      },
    },
    myInfoForWhenIamGone: {
      title: "Información para cuando ya no esté aquí",
      subcategories: {
        'social networks': "Redes sociales",
        pictures: "Fotos",
        memories: "Memorias",
        books: "Libros",
        'mi pet': "Mi mascota",
        collections: "Colecciones",
        'last notes': "Últimas notas",
        epitaph: "Epitáfio",
        clothes: "Ropa"
      },
    },
    whatToDoWhenIamGone: {
      title: "Que hacer cuando me vaya",
      subcategories: {
        'join me': "Deseo que me acompañen",
        'not join me': "Deseo que no me acompañen",
        'artificial life': "Deseo vida artificial",
        'organ donor': "Deseo ser donador de órganos",
        'my rests': "Deseo ser"
      },
    },
  };
export default InfoModal;
