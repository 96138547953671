import React, { createContext, useContext, useEffect, useState } from "react";
import useAuthService from "../services/authService.js";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [userInfo, setUserInfo] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authService = useAuthService();
  const [isLoading, setIsLoading] = useState(true);

  async function register(newUser) {
    try {
      const res = await authService.register(newUser);
      const user = await handleAuthInfo(res);
      if (user) {
        setIsAuthenticated(true)
      }
      return res;
    } catch (error) {
      console.error("Hubo un error al intentar registrar al usuario: ", error);
    }
  }

  async function login(payload) {
    try {
      const res = await authService.login(payload);
      if (res) {
        const user = await handleAuthInfo(res);
        setIsAuthenticated(true);
        setUserInfo(user);
        return user;
      }
      return null;
    } catch (error) {
      console.error("Hubo un error al intentar iniciar sesion: ", error);
    }
  }

  async function logout() {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Hubo un error al intentar cerrar sesion: ", error);
    }
  }

  async function refreshTheToken() {
    const rToken = localStorage.getItem("refreshToken");
    try {
      const authPayload = await authService.refreshToken({
        refreshToken: rToken,
      });
      if (authPayload) {
        await handleAuthInfo(authPayload);
        return authPayload;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Hubo un error al intentar refrescar el token: ", error);
    }
  }

  async function handleAuthInfo(authPayload) {
    const { accessToken, refreshToken } = authPayload;
    const user = await decodeToken(accessToken);

    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    setToken(accessToken);
    setRefreshToken(refreshToken);
    setUserInfo(user);

    return user;
  }

  async function decodeToken(token) {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error("Error al decodificar el token:", error);
    }
  }

  async function checkToken() {
    try {
      const currentRefreshToken = localStorage.getItem("refreshToken");
      if (currentRefreshToken) {
        const authPayload = await refreshTheToken();

        if (authPayload) {
          await handleAuthInfo(authPayload);
          setIsAuthenticated(true);
        }
      }
    } catch (error) {
      console.error("Error al chequear el token:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateToken() {
    try {
      const currentToken = localStorage.getItem("token");

      if (!currentToken) {
        console.warn("No hay token disponible para actualizar.");
        return null;
      }

      const updatedAuthPayload = await authService.updateToken({ accessToken: currentToken });

      if (updatedAuthPayload && updatedAuthPayload.accessToken) {
        const updatedUser = await handleAuthInfo(updatedAuthPayload);
        setUserInfo(updatedUser);
      } else {
        console.warn("No se pudo actualizar el token.");
        return null;
      }
    } catch (error) {
      console.error("Error al actualizar el token:", error);
      return null;
    }
  }

  async function verifyCodes(emailCode, userId) {
    try {
      const res = await authService.verifyCodes(emailCode, userId);
      return res;
    } catch (error) {
      console.error("Hubo un error al verificar los códigos", error);
      throw new Error('Error al verificar los códigos');
    }
  }

  useEffect(() => {
    checkToken();
    /* eslint-disable-next-line */
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        userInfo,
        login,
        logout,
        updateToken,
        register,
        refreshTheToken,
        setIsAuthenticated,
        verifyCodes,
        isAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
