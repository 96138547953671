import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../components/organisms/Layout";
import { useAuth } from "../contexts/AuthContext";
import Login from "../components/pages/Login";
import Register from "../components/pages/Register";
import Home from "../components/pages/Home";
import NotFound from "../components/pages/NotFound";
import ExamplePage from "../components/pages/ExamplePage";
import BeneficiaryHolders from "../components/pages/BeneficiaryHolders";
import Verification from "../components/pages/Verification";
import Link from "../components/pages/Link";

function RedirectToVerification() {
  const { isAuthenticated, userInfo } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !userInfo?.verify_email) {
      navigate("/verificacion");
    }
  }, [isAuthenticated, userInfo, navigate]);

  return null;
}

function ProtectedRoute({ element }) {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  return !isLoading && isAuthenticated ? element : null;
}

export default function Navigation() {

  return (
    <Router>
      <RedirectToVerification />
      <Routes>
        <Route element={<ProtectedRoute element={<Layout />} />}>
          <Route path="/titulares" element={<BeneficiaryHolders />} />
          <Route path="/titulares/:id" element={<ExamplePage />} />
          <Route path="/verificacion" element={<Verification />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/registro" element={<Register />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/recuperacion" element={<ExamplePage />} />
          <Route path="/identificacion/:codigo" element={<Link />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}
