import { ChakraProvider } from "@chakra-ui/react";
import theme from "./utils/theme.js";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { MessageProvider } from "./contexts/MessageContext.jsx";
import Navigation from "./navigation/Navigation.jsx";
import { HolderProvider } from "./contexts/HoldersContext.jsx";
export default function App() {
  console.log("REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <MessageProvider>
          <HolderProvider>
            <Navigation />
          </HolderProvider>
        </MessageProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}
