import React from "react";
import { useColorMode, Button, Flex, Box, Text, IconButton, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaSun, FaMoon, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";

export default function NavBar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  return (
    <Flex as="header" align="center" justify="space-between" p={4} boxShadow="md" bg={colorMode === "light" ? "white" : "gray.700"}>
      <Box>
        <Text m={0} fontSize="lg" fontWeight="bold" color={colorMode === "light" ? "gray.800" : "white"}>
          RIP Beneficiarios
        </Text>
      </Box>

      {isAuthenticated ? (
        <Flex align="center" gap={4}>
          <Text m={0} display={{ base: "none", sm: "inherit" }} fontSize="lg" fontWeight="bold" color={colorMode === "light" ? "gray.800" : "white"}>
            Beneficiario
          </Text>

          <Tooltip label="Cerrar sesión" aria-label="Cerrar sesión">
            <IconButton
              aria-label="Cerrar sesión"
              icon={<FaSignOutAlt />}
              onClick={() => {
                logout();
                navigate("/login");
              }}
              colorScheme="red"
              variant="ghost"
            />
          </Tooltip>

          <Tooltip label={colorMode === "light" ? "Cambiar a tema oscuro" : "Cambiar a tema claro"} aria-label="Cambiar tema">
            <IconButton
              aria-label="Cambiar tema"
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              onClick={toggleColorMode}
              variant="ghost"
              color={colorMode === "light" ? "gray.800" : "white"}
            />
          </Tooltip>
        </Flex>
      ) : (
        <Flex align="center" gap={4}>
          <Button
            colorScheme="green"
            onClick={() => navigate("/login")}
            variant="solid"
          >
            Iniciar sesión
          </Button>

          <Tooltip label={colorMode === "light" ? "Cambiar a tema oscuro" : "Cambiar a tema claro"} aria-label="Cambiar tema">
            <IconButton
              aria-label="Cambiar tema"
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              onClick={toggleColorMode}
              variant="ghost"
              color={colorMode === "light" ? "gray.800" : "white"}
            />
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
}
