import React, { useState } from "react";
import { Box, Button, Text, Heading } from "@chakra-ui/react";
import InfoModal from "./InfoModal";
import { useHolders } from "../../contexts/HoldersContext";

function CardHolder({ titular, setLoadingScreen }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [holderInfo, setHolderInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const { obtenerInfoTitular, solicitarInfoTitular } = useHolders();

  async function onClickSolicitarInfo() {
    setLoadingScreen(true);
    setLoading(true);
    setError(null);
    setResponseMessage(null);
    try {
      const response = await solicitarInfoTitular(titular.userObjectId);
      if (response) {
        setResponseMessage(response);
      } else {
        setError("No se encontró información");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingScreen(false);
      setLoading(false);
    }
  }

  const openModal = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await obtenerInfoTitular(titular.userObjectId);
      if (response) {
        setHolderInfo(response);
        setIsModalOpen(true);
      } else {
        setError("No se encontró información");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg={"whiteAlpha.500"}
      p={5}
      width={{ base: "100%", sm: "250px", md: "300px" }}
      textAlign="center"
      boxShadow="md"
      transition="all 0.2s ease"
      _hover={{ transform: "scale(1.02)", boxShadow: "lg" }}
      mb={4}
      minHeight={"270px"}
      _dark={{ bg: "gray.700" }}
    >
      <Heading size="lg" color="green.400" mb={6}>
        {titular.userName}
      </Heading>

      <Button
        width="100%"
        padding="12px"
        mb={4}
        isDisabled={!titular.visibilidad_status}
        colorScheme="green"
        variant="outline"
        onClick={openModal}
      >
        Ver Info
      </Button>

      <Button
        width="100%"
        padding="12px"
        isDisabled={
          (titular.solicitud_status === false &&
            titular.visibilidad_status === true) ||
          (titular.solicitud_status === true &&
            titular.visibilidad_status === false)
        }
        colorScheme="teal"
        variant="outline"
        onClick={onClickSolicitarInfo}
      >
        Solicitar Info
      </Button>

      <Text mt={5} p={0} color="gray.500" fontSize="md">
        {titular.solicitud_status
          ? "En espera..."
          : titular.visibilidad_status
          ? "Información disponible"
          : "Puede solicitar la informacion"}
      </Text>

      {responseMessage && (
        <Text m={0} p={0} color="green.500" fontSize="md">
          {responseMessage}
        </Text>
      )}
      {error && (
        <Text m={0} p={0} color="red.500" fontSize="md">
          {error}
        </Text>
      )}

      <InfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        name={titular.name}
        holderInfo={holderInfo}
        isLoading={loading}
      />
    </Box>
  );
}

export default CardHolder;
