import React, { createContext, useContext, useState } from "react";
import useHolderService from "../services/holderService.js";
const HolderContext = createContext(null);

export const HolderProvider = ({ children }) => {
  const [holders, setHolders] = useState([]);
  const holderService = useHolderService();

  async function obtenerTitulares() {
    try {
      const res = await holderService.obtenerTitulares();

      if (res.status === "success") {
        setHolders(res.data.holders);
      }
    } catch (error) {
      console.error("Hubo un error al obtener los titulares: ", error);
    }
  }

  async function obtenerInfoTitular(payload) {
    try {
      const res = await holderService.obtenerInfoTitular(payload);
      if (res.status === "success") {
        return res.data.user;
      }
    } catch (error) {
      console.error("Hubo un error al obtener los titulares: ", error);
      throw new Error("Error al obtener la información");
    }
  }

  async function solicitarInfoTitular(payload) {
    try {
      const res = await holderService.solicitarInfoTitular(payload);
      if (res.status === "success") {
        await obtenerTitulares();
        return res.message;
      }
    } catch (error) {
      console.error("Hubo un error al obtener los titulares: ", error);
      throw new Error("Error al obtener la información");
    }
  }

  async function validarCodigo(code, email) {
    try {
      const res = await holderService.validarCodigo(code, email);
      return res;
    } catch (error) {
      console.error("Hubo un error al validar el código: ", error);
      throw new Error("Error al validar el código");
    }
  }

  return (
    <HolderContext.Provider
      value={{
        holders,
        obtenerTitulares,
        obtenerInfoTitular,
        solicitarInfoTitular,
        validarCodigo,
      }}
    >
      {children}
    </HolderContext.Provider>
  );
};

export const useHolders = () => useContext(HolderContext);
