import useAxios from "../utils/axiosWithInterceptors";

export default function useHolderService() {
  const { axiosWithInterceptors } = useAxios();

  const obtenerTitulares = async () => {
    try {
      const response = await axiosWithInterceptors.get("/api/beneficiary/holders");
      return response.data;
    } catch (error) {
      console.error("Error al intentar obtener los titulares: ", error);
    }
  };

  const obtenerInfoTitular = async (holderId) => {
    try {
      const response = await axiosWithInterceptors.get(`/api/beneficiary/holderInfo?id=${holderId}`);
      return response.data;
    } catch (error) {
      console.error("Error al intentar obtener los titulares: ", error);
    }
  };

  const solicitarInfoTitular = async (holderId) => {
    try {
      const response = await axiosWithInterceptors.post(`/api/beneficiary/solicitarHolderInfo?id=${holderId}`);
      return response.data;
    } catch (error) {
      console.error("Error al intentar obtener los titulares: ", error);
    }
  };

  const validarCodigo = async (code, email) => {
    try {
      const response = await axiosWithInterceptors.post(`/api/beneficiary/identificacion`, { code, email });
      return response.data;
    } catch (error) {
      console.error("Error al intentar validar el mail: ", error);
    }
  };

  return {
    obtenerTitulares,
    obtenerInfoTitular,
    solicitarInfoTitular,
    validarCodigo,
  };
}
