import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext.jsx";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default function useAxios() {
  const { refreshTheToken } = useAuth();

  async function onRequest(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  }

  async function onRequestError(error) {
    return Promise.reject(error);
  }

  async function onResponse(response) {
    return response;
  }

  async function onResponseError(error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshTheToken();
      const newToken = localStorage.getItem("token");
      originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

      return axiosInstance(originalRequest);
    } else {
      console.error("Error al realizar la peticion: ", error);
      return error;
    }
    
  }

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      onRequest,
      onRequestError
    );
    const responseInterceptor = axiosInstance.interceptors.response.use(
      onResponse,
      onResponseError
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
    /* eslint-disable-next-line */
  }, []);

  return { axiosWithInterceptors: axiosInstance };
}
