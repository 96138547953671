import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      h="100vh"
      w="100vw"
      p={3}
    >
      <h2>404 - Página No Encontrada</h2>
      <p style={{ textAlign: "center" }}>
        Lo sentimos, no hemos podido encontrar la página que buscas.
      </p>
      <Button
        variant="primary"
        fontSize={{ base: "sm", md: "md" }}
        mt={1}
        onClick={goHome}
      >
        Volver al Inicio
      </Button>
    </Flex>
  );
}
