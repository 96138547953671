import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    Flex,
    Link,
    FormControl,
    FormErrorMessage,
    Spinner
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from '../../assets/ripLogo.png';
import { useAuth } from '../../contexts/AuthContext';

export default function Register() {
    const [showPassword, setShowPassword] = useState(false);
    const [formState, setFormState] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const { register } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const toggleShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setFormState((prev) => ({ ...prev, email: emailParam }));
        }
    }, [searchParams]);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const validatePassword = (password) =>
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/.test(password);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleRegister = async () => {
        const { email, password, confirmPassword } = formState;

        if (!email || !password || !confirmPassword) {
            setError('Todos los campos son obligatorios.');
            return;
        }

        if (!validateEmail(email)) {
            setError('Por favor, ingrese un correo electrónico válido.');
            return;
        }

        if (!validatePassword(password)) {
            setError('La contraseña debe tener al menos 6 caracteres, una letra mayúscula, un número y un símbolo.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden.');
            return;
        }

        setError('');
        setIsLoading(true);

        const user = { email, password };

        try {
            const res = await register(user);
            if (res?.status === 409) {
                setError(res.response?.data?.msg);
            } else {
                navigate("/verificacion");
            }
        } catch (error) {
            console.error("Se ha producido un error al intentar crear el usuario");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={4}
        >
            <Box
                bg="white"
                _dark={{ bg: "gray.700" }}
                p={8}
                maxW="sm"
                borderRadius="lg"
                boxShadow="lg"
                textAlign="center"
                width={{ base: "80%", md: "none" }}
                minW={{ base: "auto", md: "400px" }}
            >
                <Box my={2}>
                    <Flex alignItems="center" justifyContent="center">
                        <img src={logo} width={140} height={140} alt="Logo" />
                    </Flex>
                    <Text fontSize="2xl" fontWeight="bold" color="green.600" _dark={{ color: "green.300" }}>
                        Crear Cuenta
                    </Text>
                </Box>
                <Stack spacing={4} my={8}>
                    <FormControl isInvalid={error && !formState.email}>
                        <Text mb={1} fontSize="sm" color="gray.600" _dark={{ color: "gray.200" }} textAlign="left">
                            Correo electrónico *
                        </Text>
                        <Input
                            px={2}
                            name="email"
                            placeholder="Ingresa tu correo"
                            variant="filled"
                            bg="gray.100"
                            _dark={{ bg: "gray.600" }}
                            value={formState.email}
                            onChange={handleInputChange}
                        />
                        {!validateEmail(formState.email) && formState.email && (
                            <FormErrorMessage>Correo electrónico no válido</FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl isInvalid={error && (!formState.password || !validatePassword(formState.password))}>
                        <Text mb={1} fontSize="sm" color="gray.600" _dark={{ color: "gray.200" }} textAlign="left">
                            Contraseña *
                        </Text>
                        <InputGroup>
                            <Input
                                px={2}
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Ingresa tu contraseña"
                                variant="filled"
                                bg="gray.100"
                                _dark={{ bg: "gray.600" }}
                                value={formState.password}
                                onChange={handleInputChange}
                            />
                            <InputRightElement>
                                <Button h="1.75rem" p={1} size="sm" onClick={toggleShowPassword}>
                                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl isInvalid={error && formState.password !== formState.confirmPassword}>
                        <Text mb={1} fontSize="sm" color="gray.600" _dark={{ color: "gray.200" }} textAlign="left">
                            Confirmar contraseña *
                        </Text>
                        <InputGroup>
                            <Input
                                px={2}
                                name="confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Repite tu contraseña"
                                variant="filled"
                                bg="gray.100"
                                _dark={{ bg: "gray.600" }}
                                value={formState.confirmPassword}
                                onChange={handleInputChange}
                            />
                            <InputRightElement>
                                <Button h="1.75rem" p={1} size="sm" onClick={toggleShowPassword}>
                                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    {error && <Text m={0} color="red.400" fontSize="sm">{error}</Text>}
                </Stack>

                <Button
                    colorScheme="green"
                    width="full"
                    mb={4}
                    onClick={handleRegister}
                    isLoading={isLoading}
                    spinner={<Spinner size="sm" />}
                >
                    Registrarse
                </Button>

                <Box mt={4} borderTop="1px" borderColor="gray.300" _dark={{ borderColor: "gray.600" }} pt={4}>
                    <Text fontSize="sm" color="gray.600" _dark={{ color: "gray.200" }}>
                        ¿Ya tienes una cuenta?
                    </Text>
                    <Link color="green.600" _dark={{ color: "green.300" }} onClick={() => navigate("/login")} fontSize="sm" href="#">
                        Inicia sesión aquí
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}
