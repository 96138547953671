import React, { useState } from "react";
import { Box, Button, Input, Stack, Text, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function Verification() {
    const [emailCode, setEmailCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const { verifyCodes, userInfo, updateToken } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const res = await verifyCodes(emailCode, userInfo._id);
            if (res?.status === "success") {
                await updateToken();
                navigate("/titulares");
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            console.error("Error al verificar el código:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={4}
        >
            <Box
                bg="white"
                p={8}
                maxW="sm"
                borderRadius="lg"
                boxShadow="lg"
                _dark={{ bg: "gray.700" }}
                textAlign="center"
                minW={{ base: "auto", md: "400px" }}
                width={{ base: "80%", md: "none" }}
            >
                <Text fontSize="xl" fontWeight="bold" color="green.600" _dark={{ color: "green.300" }}>
                    Verificación de Cuenta
                </Text>
                <Text
                    fontSize="sm"
                    mt={4}
                    mb={6}
                    textAlign="left"
                >
                    Su cuenta ha sido creada temporalmente, necesitamos que valide su
                    información para verificar la misma. Dispone de 10 minutos para hacerlo,
                    caso contrario, tendrá que volver a crear la cuenta.
                </Text>

                <Stack spacing={4} my={8}>
                    <Box>
                        <Text mb={1} fontSize="sm" textAlign="left">
                            Código de verificación al correo
                        </Text>
                        <Input
                            placeholder="Ingresa el código de tu correo"
                            variant="filled"
                            name="emailCode"
                            value={emailCode}
                            onChange={(e) => setEmailCode(e.target.value)}
                        />
                    </Box>
                </Stack>
                {error && (
                    <Text my={3} color="red.400">
                        El código es incorrecto
                    </Text>
                )}
                <Button
                    onClick={handleSubmit}
                    colorScheme="green"
                    width="full"
                    mb={4}
                    isLoading={isLoading}
                    spinner={<Spinner size="sm" />}
                >
                    Verificar
                </Button>
            </Box>
        </Box>
    );
}
