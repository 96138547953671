import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Divider,
  Accordion,
  useColorModeValue,
} from "@chakra-ui/react";
import categoryConfig from "../../utils/categoryConfig";
import { ChevronDownIcon } from "@chakra-ui/icons";

const InfoAccordion = ({ categoryData, categoryName }) => {
  const textColor = useColorModeValue("gray.600", "whiteAlpha.900"); 
  const subTextColor = useColorModeValue("blue.600", "blue.300"); 

  if (!categoryData || Object.keys(categoryData).length === 0) {
    return (
      <AccordionItem>
        <AccordionButton fontWeight="bold">
          <Box as="span" flex="1" textAlign="left" fontWeight="bold">
            {categoryName}
          </Box>
        </AccordionButton>
        <AccordionPanel>
          <Text>No hay información disponible.</Text>
        </AccordionPanel>
      </AccordionItem>
    );
  }

  const renderSubCategory = (item) => {
    return Object.entries(item)
      .filter(([subKey]) => categoryConfig.subcategories[subKey])
      .map(([subKey, subValue]) => {
        const displayName = categoryConfig.subcategories[subKey];
        const displayValue =
          typeof subValue === "boolean"
            ? subValue
              ? "Sí"
              : "No"
            : subValue || "No especificado";

        return (
          <Text key={subKey} mb={2} fontSize="sm" color={textColor}>
            <strong style={{ color: subTextColor }}>{displayName}:</strong>{" "}
            <span>{displayValue}</span>
          </Text>
        );
      });
  };

  return (
    <AccordionItem
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      boxShadow="md"
      mb={4}
      _hover={{ borderColor: "#248A3E" }}
    >
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left" fontWeight="bold">
          {categoryName}
        </Box>
        <ChevronDownIcon />
      </AccordionButton>
      <AccordionPanel>
        {Object.entries(categoryData)
          .filter(([key]) => categoryConfig.subcategories[key])
          .map(([key, value]) => {
            const displayName = categoryConfig.subcategories[key];

            if (Array.isArray(value)) {
              return (
                <Accordion key={key} allowMultiple>
                  <AccordionItem
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    boxShadow="md"
                    mb={4}
                    _hover={{ borderColor: "#248A3E" }}
                  >
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight="bold"
                      >
                        {displayName}
                      </Box>
                      <ChevronDownIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      {value.map((item, index) => (
                        <React.Fragment key={index}>
                          <div>{renderSubCategory(item)}</div>
                          {index < value.length - 1 && <Divider my={4} />}
                        </React.Fragment>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            }

            if (typeof value === "object" && value !== null) {
              return (
                <Accordion key={key} allowMultiple>
                  <AccordionItem
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    boxShadow="md"
                    mb={4}
                    _hover={{ borderColor: "#248A3E" }}
                  >
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight="bold"
                      >
                        {displayName}
                      </Box>
                      <ChevronDownIcon />
                    </AccordionButton>
                    <AccordionPanel>{renderSubCategory(value)}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            }

            const displayValue =
              typeof value === "boolean"
                ? value
                  ? "Sí"
                  : "No"
                : value || "No especificado";

            return (
              <Text key={key} mb={2} color={textColor}>
                <strong>{displayName}:</strong> {displayValue}
              </Text>
            );
          })}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default InfoAccordion;
