import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.100",
        _dark: {
          bg: "gray.800",
        },
      },
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    primary: {
      500: "#248A3E",
    },
    secondary: {
      500: "#94DC74",
    },
  },
});

export default theme;
