import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const BasicDataAccordion = ({ categoryData }) => {
  const categoryConfig = {
    title: "Datos Básicos",
    subcategories: {
      birthDay: "Fecha de Nacimiento",
      gender: "Género",
      bloodType: "Tipo de Sangre",
      diseases: "Enfermedades",
      alergies: "Alergias",
      policy: "Póliza",
      medications: "Medicaciones",
      insurances: "Seguro Médico",
      hasHospital: "Hospital",
      emergencyContactName: "Nombre Contacto de Emergencia",
      emergencyContactPhone: "Número Contacto de Emergencia",
      emergencyContactEmail: "Correo Electrónico de Contacto de Emergencia",
      hasIntlCoverage: "Tiene Cobertura Internacional",
    },
  };

  if (!categoryData || Object.keys(categoryData).length === 0) {
    return (
      <AccordionItem>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {categoryConfig.title}
          </Box>
        </AccordionButton>
        <AccordionPanel>
          <Text>No hay información disponible.</Text>
        </AccordionPanel>
      </AccordionItem>
    );
  }

  return (
    <AccordionItem
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      boxShadow="md"
      mb={4}
      _hover={{ borderColor: "#248A3E" }}
    >
      <AccordionButton fontWeight="bold">
        <Box as="span" flex="1" textAlign="left">
          {categoryConfig.title}
        </Box>
        <ChevronDownIcon />

      </AccordionButton>
      <AccordionPanel>
        {Object.entries(categoryData).map(([key, value]) => {
          if (!categoryConfig.subcategories[key]) return null;

          const displayName = categoryConfig.subcategories[key];

          const displayValue =
            typeof value === "boolean" ? (value ? "Sí" : "No") : value;

          return (
            <Text key={key} mb={2}>
              <strong>{displayName}:</strong>{" "}
              {Array.isArray(displayValue)
                ? displayValue.join(", ")
                : displayValue || "No especificado"}
            </Text>
          );
        })}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BasicDataAccordion;
