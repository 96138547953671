import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import logo from "../../assets/ripLogo.png";
import { useAuth } from "../../contexts/AuthContext";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const { login } = useAuth();
  const [errors, setErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  async function loginUser() {
    try {
      setIsLoading(true)
      const res = await login(formData);
      if (res) {
        navigate("/titulares")
      } else {
        setErrors(true);
      }
    } catch (error) {
      setErrors(true);
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Box
        bg="white"
        _dark={{ bg: "gray.700" }}
        p={8}
        maxW="sm"
        borderRadius="lg"
        boxShadow="lg"
        textAlign="center"
        minW={{ base: "auto", md: "400px" }}
        width={{ base: "80%", md: "none" }}
      >
        <Box my={2}>
          <Box my={5}>
            <Flex alignItems="center" justifyContent="center">
              <img src={logo} width={140} height={140} alt="Logo" />
            </Flex>
          </Box>
          <Text
            fontSize="sm"
            mb={1}
            fontWeight="bold"
            color="green.600"
            _dark={{ color: "green.300" }}
          >
            Beneficiarios
          </Text>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color="green.600"
            _dark={{ color: "green.300" }}
          >
            Iniciar Sesión
          </Text>
        </Box>
        <Stack spacing={4} my={8}>
          <Box>
            <Text
              mb={1}
              fontSize="sm"
              color="gray.600"
              _dark={{ color: "gray.200" }}
              textAlign="left"
            >
              Correo electrónico
            </Text>
            <Input
              px={2}
              placeholder="Ingresa tu correo"
              variant="filled"
              bg="gray.100"
              _dark={{ bg: "gray.600" }}
              name="email"
              onChange={handleChange}
            />
          </Box>

          <Box>
            <Text
              mb={1}
              fontSize="sm"
              color="gray.600"
              _dark={{ color: "gray.200" }}
              textAlign="left"
            >
              Contraseña
            </Text>
            <InputGroup>
              <Input
                px={2}
                type={showPassword ? "text" : "password"}
                placeholder="Ingresa tu contraseña"
                variant="filled"
                bg="gray.100"
                _dark={{ bg: "gray.600" }}
                name="password"
                onChange={handleChange}
              />
              <InputRightElement>
                <Button
                  h="1.75rem"
                  p={1}
                  size="sm"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors && (
              <Text mt={5} mb={0} color="red.400">
                Credenciales incorrectas
              </Text>
            )}
          </Box>
        </Stack>

        <Button
          onClick={() => loginUser()}
          colorScheme="green"
          width="full"
          mb={4}
          isLoading={isLoading}
          spinner={<Spinner size="sm" />}
        >
          Ingresar
        </Button>

        <Link
          color="green.600"
          _dark={{ color: "green.300" }}
          fontSize="sm"
          href="#"
        >
          Olvidé mi contraseña
        </Link>

        <Box
          mt={4}
          borderTop="1px"
          borderColor="gray.300"
          _dark={{ borderColor: "gray.600" }}
          pt={4}
        >
          <Text fontSize="sm" color="gray.600" _dark={{ color: "gray.200" }}>
            ¿Aún no has creado una cuenta de beneficiario?
          </Text>
          <Link
            color="green.600"
            _dark={{ color: "green.300" }}
            onClick={() => navigate("/registro")}
            fontSize="sm"
            href="#"
          >
            Creá tu cuenta aquí
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
