import React, { useState, useEffect } from "react";
import { Box, Button, Input, Stack, Text, Spinner } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useHolders } from "../../contexts/HoldersContext";

export default function IdentificationForm() {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { codigo } = useParams();
    const { validarCodigo } = useHolders();

    useEffect(() => {
        setCode(codigo || "");
    }, [codigo]);

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");

        if (!isEmailValid(email)) {
            setError("Por favor, ingresa un correo electrónico válido.");
            setIsLoading(false);
            return;
        }

        try {
            const res = await validarCodigo(code, email);
            if (res?.isRegistered && res?.isVerified) {
                navigate(`/login`);
                return;
            }
            if (res?.isRegistered && !res?.isVerified) {
                setError("El correo electrónico ingresado se encuentra pendiente la verificación.");
                return;
            }
            if (!res?.isValidCode) {
                setError("El código ingresado es inválido o ya se ha consumido.");
                return;
            }
            navigate(`/registro?email=${email}`);
        } catch (err) {
            setError(err.message || "Error al procesar la solicitud.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={4}
        >
            <Box
                bg="white"
                p={8}
                maxW="sm"
                borderRadius="lg"
                boxShadow="lg"
                _dark={{ bg: "gray.700" }}
                textAlign="center"
                minW={{ base: "auto", md: "400px" }}
                width={{ base: "80%", md: "none" }}
            >
                <Text fontSize="xl" fontWeight="bold" color="green.600" _dark={{ color: "green.300" }}>
                    Vincularse
                </Text>
                <Text fontSize="sm" mt={4} mb={6} textAlign="left">
                    Ingrese su correo electrónico para continuar con el proceso de registro. El mismo lo vinculará con su titular.
                </Text>

                <Stack spacing={4} my={8}>
                    <Box>
                        <Text mb={1} fontSize="sm" textAlign="left">
                            Código
                        </Text>
                        <Input
                            placeholder="Código único"
                            variant="filled"
                            name="code"
                            value={code}
                            isReadOnly
                        />
                    </Box>

                    <Box>
                        <Text mb={1} fontSize="sm" textAlign="left">
                            Correo electrónico
                        </Text>
                        <Input
                            placeholder="Ingresa tu correo"
                            variant="filled"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                </Stack>

                {error && (
                    <Text my={3} color="red.400">
                        {error}
                    </Text>
                )}

                <Button
                    onClick={handleSubmit}
                    colorScheme="green"
                    width="full"
                    mb={4}
                    isLoading={isLoading}
                    spinner={<Spinner size="sm" />}
                >
                    Vincular Titular
                </Button>
            </Box>
        </Box>
    );
}
