import React, { useEffect, useState } from "react";
import { Box, Heading, Flex, Text, Spinner } from "@chakra-ui/react";
import CardHolder from "../atoms/CardHolder";
import { useHolders } from "../../contexts/HoldersContext";

function BeneficiaryHolders() {
  const { holders, obtenerTitulares } = useHolders();
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    await obtenerTitulares();
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Box textAlign="center" p={4} mt={5}>
      <Heading mb={6}>Titulares</Heading>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="200px"
        >
          <Spinner color="#248A3E" size="xl" />
        </Box>
      ) : (
        <>
          {holders.length > 0 ? (
            <Flex
              justify="center"
              gap={6}
              direction={{ base: "column", md: "row" }}
              wrap="wrap"
              align="center"
            >
              {holders.map((titular, index) => (
                <CardHolder
                  key={index}
                  titular={titular}
                  setLoadingScreen={setLoading}
                />
              ))}
            </Flex>
          ) : (
            <Text fontSize="2xl" fontWeight="bold" mt={10}>
              No eres beneficiario de ningún titular
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default BeneficiaryHolders;
