const categoryConfig = {
    subcategories: {
      "certificate of incorporation": "Acta Constitutiva",
      stocks: "Acciones",
      "property deeds": "Escrituras",
      certificates: "Certificados",
      others: "Otros",
      contracts: "Contratos",
      passport: "Pasaporte",
      licence: "Licencia",
      ine: "INE",
      afore: "AFORE",
      imss: "IMSS",
      "tax records": "Registros Fiscales",
      "promissory notes": "Pagarés",
      will: "Testamento",
      "insurance policies": "Pólizas de Seguro",
      number: "Número",
      trust: "Fideicomiso",
      type: "Tipo",
      description: "Descripción",
      "social networks": "Redes sociales",
      pictures: "Fotos",
      memories: "Mis Recuerdos",
      books: "Libros",
      "mi pet": "Mi mascota",
      collections: "Mis Colecciones",
      "last notes": "Últimas notas",
      epitaph: "Epitáfio",
      clothes: "Ropa",
      "join me": "Deseo que me acompañen",
      "not join me": "Deseo que no me acompañen",
      "artificial life": "Deseo vida artificial",
      "organ donor": "Deseo ser donador de órganos",
      "my rests": "Deseo ser",
      "life insurance": "Seguro de Vida y Gastos Médicos",
      insurance: "Aseguradora",
      policy: "Póliza",
      assessor: "Asesor",
      "cars/jewelry": "Autos o Joyas",
      credits: "Créditos",
      institution: "Institución",
      branch: "Sucursal",
      account: "Número de Cuenta",
      "bank accounts": "Cuentas Bancarias",
      bank: "Banco",
      beneficiaries: "Beneficiarios",
      "funeral services": "Servicios Funerarios",
      "real state": "Inmuebles",
      location: "Locación",
      info: "Información",
      investment: "Inversiones",
      adInfo: "Información Adicional",
      "pending procedures": "Trámites Pendientes",
      code: "Código",
      "safety box": "Caja de Seguridad",
      "credit card insurance": "Seguro de Tarjeta de crédito",
      lawyer: "Mi abogado",
      notary: "Mi notario",
      executor: "Mi albacea",
      "insurance agent": "Mi asesor de seguros",
      "personal assistant": "Mi asistente personal",
      "funeral services advisor": "Asesor de servicios funerarios",
      "guardian of my children": "Deseo que a falta mía...",
      other: "Otro",
      accountant: "Mi contador",
      "in case of being retired": "En caso de ser jubilado...",
      name: "Nombre",
      phone: "Teléfono",
      firstName: "Nombre",
      lastName: "Apellido",
      facebook: "Facebook",
      instagram: "Instagram",
      snapchat: "Snapchat",
      twitter: "Twitter",
      ashes: "Cenizas",
      relationships: "Relación",
      employee_number: "Número de empleado",
      boss_name: "Nombre jefe directo",
      office_phone: "Teléfono oficina",
      email: "Email",
      comentarios: "Comentarios",
      pension: "Pensión",
      Phone: "Teléfono"
    },
  };

  export default categoryConfig;