import axiosInstance from "../utils/axiosInstance.js";

export default function useAuthService() {
  const register = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/api/beneficiary/auth/register",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error al intentar registrar al usuario: ", error);
      return error
    }
  };

  const login = async (payload) => {
    const response = await axiosInstance.post(
      "/api/beneficiary/auth/login",
      payload
    );
    return response.data;
  };

  const refreshToken = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/api/beneficiary/auth/refresh",
        payload
      );

      return response.data;
    } catch (error) {
      console.error("Error al intentar refrescar el token: ", error);
    }
  };

  const updateToken = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/api/beneficiary/auth/update",
        payload
      );

      return response.data;
    } catch (error) {
      console.error("Error al intentar actualizar el token: ", error);
    }
  };

  const verifyCodes = async (emailCode, userId) => {
    try {
      const response = await axiosInstance.post(
        "/api/beneficiary/auth/verify",
        {
          emailVerificationCode: emailCode,
          userId: userId,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error al intentar verificar los códigos: ", error);
      return error
    }
  };

  return {
    register,
    login,
    verifyCodes,
    refreshToken,
    updateToken
  };
}
