import React from "react";
import { Box, Button, Text, Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/ripLogo.png";

export default function Home() {
    const navigate = useNavigate();

    return (
        <Box
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={4}
        >
            <Box
                bg="white"
                _dark={{ bg: "gray.700" }}
                p={8}
                maxW="sm"
                borderRadius="lg"
                boxShadow="lg"
                textAlign="center"
                minW={{ base: "auto", md: "400px" }}
                width={{ base: "80%", md: "none" }}
            >
                <Box my={2}>
                    <Box my={5}>
                        <Flex alignItems="center" justifyContent="center">
                            <img src={logo} width={140} height={140} alt="Logo" />
                        </Flex>
                    </Box>
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="green.600"
                        _dark={{ color: "green.300" }}
                    >
                        ¡Bienvenido!
                    </Text>
                </Box>
                <Stack spacing={4} my={8}>
                    <Text
                        fontSize={{ base: "md", md: "lg" }}
                        color="gray.600"
                        _dark={{ color: "gray.200" }}
                        textAlign="center"
                        m={0}
                    >
                        Usted se encuentre actualmente en el apartado de beneficiarios de RIP Alive.
                    </Text>
                    <Text
                        color="gray.600"
                        _dark={{ color: "gray.200" }}
                        textAlign="center"
                        fontSize={{ base: "md", md: "lg" }}
                    >
                        Los beneficiarios son aquellas personas que han sido añadidas por los titulares de las cuentas de Rip Alive y quienes tendrán acceso a la información de la cuenta en caso de que el titular fallezca.
                    </Text>
                </Stack>

                <Button
                    colorScheme="green"
                    width="full"
                    onClick={() => navigate("/login")}
                    mb={5}
                >
                    Iniciar Sesión
                </Button>

                <Button
                    colorScheme="green"
                    variant={"outlied"}
                    width="full"
                    onClick={() => navigate("/registro")}
                >
                    Crear Cuenta
                </Button>
            </Box>
        </Box>
    );
}
