import React, { createContext, useContext, useState, useCallback, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [visible, setVisible] = useState(false);
    const timeoutRef = useRef(null);

    const addMessage = useCallback((newMessage) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setMessage(newMessage);
        setVisible(true);

        timeoutRef.current = setTimeout(() => {
            setVisible(false);
            timeoutRef.current = setTimeout(() => {
                setMessage(null);
            }, 300);
        }, 2000);
    }, []);

    return (
        <MessageContext.Provider value={{ addMessage }}>
            {children}
            {message && <MessageBox message={message} visible={visible} />}
        </MessageContext.Provider>
    );
};

export const useMessage = () => useContext(MessageContext);

const MessageBox = ({ message, visible }) => {
    return (
        <Box
            position="fixed"
            bottom="20px"
            left="20px"
            zIndex={1000}
            bg="green.400"
            color="white"
            borderRadius="md"
            boxShadow="md"
            py={3}
            px={5}
            opacity={visible ? 1 : 0}
            transition="opacity 0.5s"
            fontWeight={"600"}
            maxWidth={{base: "225px", md: "500px"}}
        >
            <Text fontSize={{ base: "sm", md: "md" }} m={0}>{message}</Text>
        </Box>
    );
};
